import React, { useEffect, Fragment } from "react";
import { Container, Card, Row, Col } from "reactstrap";
import BreadCrumbs from "../components/Layout/BreadCrumbs";
import TitleBar from "../components/Layout/TitleBar";
import SEO from "../components/SEO";
// import { Helmet } from "react-helmet";

const LegalNoticePage = () => {
    useEffect(() => {
        document.body.classList.add("index-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        return function cleanup() {
            document.body.classList.remove("index-page");
            document.body.classList.remove("sidebar-collapse");
        };
    });

    return (
        <Fragment>
            <SEO
                title="Legal Notice"
            />

            <div className="wrapper mt-5">
                <div className="main bg-grey">
                    <Container>
                        <TitleBar title="Legal Notice" />
                        <BreadCrumbs page="Legal Notice" />
                        <Card className="pl-4 pr-4 pb-4">
                            <Row className="mt-4">
                                <Col>
                                    <p>
                                    The material contained in this website is intended to provide general information and comment and 
                                    should not be relied upon as legal advice. Any information sent to Cannabis and Psychedelics Law 
                                    Group LLP (“CPLG”) by e-mail through this website is not confidential and does not constitute or 
                                    create a lawyer-client relationship. CPLG does not necessarily endorse material on any website 
                                    accessible through links from this website.
                                    </p>
                                    <p>
                                    All material on this site is subject to copyright and may not be reproduced in any form for commercial 
                                    purposes without the express written consent of CPLG. Anyone seeking to link to this site from any 
                                    external website must seek the consent of CPLG by sending us an email.
                                    </p>
                                    <p
                                    style={{fontWeight: 'bold'}}
                                    >
                                    Full disclaimer applying to all Cannabis and Psychedelics Law Group LLP publications:
                                    </p>   
                                    <p>
                                    This publication is intended to convey general information about legal issues and developments as of the 
                                    indicated date. It does not constitute legal advice and must not be treated or relied on as such. Always 
                                    obtain the advice of your own lawyer with respect to decisions and actions that could have legal consequences. 
                                    Statements in this publication about the law, including the interpretation of case law, are of a general 
                                    nature only and in no way pre-determine the positions that we may take with respect to a specific fact 
                                    situation or particular client matter. We do not endorse, guarantee the accuracy of, or accept any 
                                    responsibility for the content of third-party websites or materials that may be linked, quoted or otherwise 
                                    referenced in this publication. Please note that the distribution of this publication to you does not 
                                    contribute to the creation, extension or revival of a lawyer-client relationship between us and you or any 
                                    other person or entity.
                                    </p>
                                </Col>
                            </Row>
                        </Card>
                    </Container>
                </div>
            </div>
        </Fragment>
    );
};

export default LegalNoticePage;